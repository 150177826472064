import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, company, title } = about

  return (
    <div className='about center'>
      {name && (
        <h1>
          Portfolio von <span className='about__name'>{name}</span>
        </h1>
      )}
      <h1 className='about__company'>{company}</h1>
      <h2 className='about__role'>{role}</h2>
      <h2 className='about__title'>{title}</h2>
      
    </div>
  )
}

export default About
