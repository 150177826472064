const header = {
  homepage: 'https://itmertens.de',
  title: 'Portfolio Michael Mertens - IT Mertens',
  title2: 'Portfolio',
}

const about = {  
  name: 'Michael Mertens',
  company: 'IT Mertens',
  role: 'Selbstständiger Ingenieur und Informatiker',
  title: 'Bachelor of Engineering (B.Eng.)',
  social: {
    xing: 'https://www.xing.com/profile/Michael_Mertens86',
  },
}

const tasks = [
    'Neu- und Weiterentwicklung von Websites und Webapplikation',
    'Installation, Einrichtung, Anpassung, Weiterentwicklung von Webapplikationen wie z. B. Webshops',
    'Einarbeitung in bestehende Projekte und Weiterentwicklung vorhandener Software (Java, PHP, Python etc.)',
    'Entwicklung von Apps für iOS und Android (Android Studio, Xcode, Flutter, Dart, Webview etc.)',
    'Serveradministration und Einrichten von Software auf Linux und Windows (Webserver, Datenbanken, Nginx, Docker etc.)'
]

const projects = [
  {
    name: 'BBURG Data',
    description:
      'Übertragung, Verarbeitung und Visualisierung von Echtzeit-Maschinendaten von Sprenglochbohrgeräten',
    stack: ['Python', 'InfluxDB', 'PHP', 'JS', 'HTML/CSS'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'ZEITundZUTRITT Webterminal',
    description:
      'Java Webapplikation zur Personal- und Auftragszeiterfassung',
    stack: ['Java', 'JSP (Apache Tomcat)', 'Access', 'HTML/CSS'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'TECNORM B2B E-Commerce',
    description:
      'Entwicklung eines B2B Webshops mit OroCommerce, ein auf Symfony aufbauendes E-Commerce-System',
    stack: ['Symfony', 'PHP', 'ORM'],
    sourceCode: '',
    livePreview: '',
  },
]

const skills = [
  'Python',
  'PHP',
  'Symfony',
  'Java',
  'JavaScript',
  'React',
  'HTML/CSS',
  'MySQL',
  'MariaDB',
  'InfluxDB',
  'C#',
  'C++',
  'Git',
  'Nginx',
  'Apache',
  'Tomcat',
  'Docker',
  'Debian',
  'AlmaLinux',
  '...'
]

const contact = {
  email: 'm.m@mailbox.org',
  phone: '+49 179 4264620'
}

export { header, about, projects, skills, tasks, contact }
