import uniqid from 'uniqid'
import { tasks } from '../../portfolio'
import './Tasks.css'

const Tasks = () => {
  if (!tasks.length) return null

  return (
    <section className='section tasks' id='tasks'>
      <h2 className='section__title'>Was ich mache</h2>
      <ul className='tasks__list'>
        {tasks.map((task) => (
          <li key={uniqid()} className='tasks__list-item btn btn--plain'>
              {task}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Tasks
